import React from 'react'

import TechsophyLogo from '../assets/techsophy-logo.png'
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import UserService from '../utils/userService'
import { Link, NavLink } from 'react-router-dom'

const Header = () => {
  return (
    <nav className="h-12 px-4 sm:px-8 flex flex-row justify-between items-center shadow shadow-gray-200">
      <div className="flex flex-row">
        <Link to={'/'}>
          <img src={TechsophyLogo} alt="Techsophy" />
        </Link>
        {UserService.hasRole(['admin', 'security']) ? (
          <div className="flex flex-row ml-8 space-x-4 text-primary font-semibold">
            {UserService.hasRole(['admin']) ? (
              <div>
                <NavLink
                  className={({ isActive }) => (isActive ? 'underline' : '')}
                  to={'/reports'}
                >
                  <h2>Reports</h2>
                </NavLink>
              </div>
            ) : null}
            <NavLink
              className={({ isActive }) => (isActive ? 'underline' : '')}
              to={'/requests'}
            >
              <h2>Requests</h2>
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? 'underline' : '')}
              to={'/visits'}
            >
              <h2>Visits</h2>
            </NavLink>
            {UserService.hasRole(['security']) ? (
              <div>
                <NavLink
                  className={({ isActive }) => (isActive ? 'underline' : '')}
                  to={'/qr'}
                >
                  <h2>QR</h2>
                </NavLink>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {UserService.isLoggedIn() ? <LogoutButton /> : <LoginButton />}
    </nav>
  )
}

export default Header
