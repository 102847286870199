import React from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { Helmet } from 'react-helmet'

const UnKnown = () => {
  return (
    <>
      <Helmet>
        <title>Visitor Pass | Unknown</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center h-full space-y-4 px-4">
        <MdErrorOutline size={60} className="text-red-500" />
        <span className="text-primary text-sm sm:text-base">
          There is nothing to display here. Please make sure you entered the
          correct URL
        </span>
      </div>
    </>
  )
}

export default UnKnown
