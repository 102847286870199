import React, { useEffect } from 'react'
import {
  FILE_SERVER_URL,
  PROJECT_ID,
  SOCKET_PATH,
  SOCKET_URL,
  VERSION,
} from '../utils/constants'

const Chat = ({ userId }) => {
  useEffect(() => {
    let mainObj = {
      socketUrl: SOCKET_URL,
      socketPath: SOCKET_PATH,
      SSL: true,
      userId: userId,
      accessToken: '',
      currentProject: PROJECT_ID,
      fileServerUrl: FILE_SERVER_URL,
      mainTitle: 'SXP',
      chatRefresh: true,
      autoLaunch: false,
      lazyAutoLaunch: false,
      editChat: false,
      uploadDoc: true,
      defaultMessage: 'Welcome to Visitor Pass',
      version: VERSION,
      journeyTray: false,
    }
    window.embedSXPChat(mainObj)
  }, [userId])

  return <></>
}

export default Chat
