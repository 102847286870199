import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  approveRequestAsync,
  rejectRequestAsync,
  selectRequestData,
} from './requestSlice'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PageHeading from '../../components/PageHeading'
import { makeDisplayDate } from "../../utils/helper";

const RequestDetails = () => {
  const data = useSelector(selectRequestData)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const approve = async () => {
    const res = await dispatch(approveRequestAsync()).unwrap()
    navigate(`/visits/${res.objectId}`)
  }

  const reject = async () => {
    await dispatch(rejectRequestAsync())
  }

  const goToVisit = () => {
    const visitUrl = `/visits/${data.visitId}`
    navigate(visitUrl)
  }

  const purposeMessage =
    data.type === 'pre-approved'
      ? `Visit requested by ${data.host.name}`
      : `Walk-in requested by ${data.visitor.name}`

  let statusMessage = ''
  switch (data.status) {
    case 'hostRequested':
      statusMessage = 'Acceptance pending from visitor.'
      break
    case 'hostCancelled':
      statusMessage = 'The host has cancelled this request.'
      break
    case 'accessRejected':
      statusMessage = `Access rejected by Admin due to reason ${data.reason}.`
      break
    case 'visitorRejected':
      statusMessage = 'Visitor has rejected the request.'
      break
    case 'rescheduleRequested':
      statusMessage = 'Visitor has requested for a reschedule.'
      break
    case 'rescheduleApproved':
      statusMessage = 'The host has accepted the reschedule request. Acceptance pending from visitor.'
      break
    case 'rescheduleRejected':
      statusMessage = 'The host has rejected the reschedule request.'
      break
    case 'securityRejected':
      statusMessage = 'Security has rejected the request.'
      break
    case 'visitStarted':
      statusMessage = 'Visit has started.'
      break
    case 'visitCompleted':
      statusMessage = 'Visit for this request is already completed.'
      break
    case 'walkinRequested':
      statusMessage = 'Acceptance pending from Host for the walk-in request.'
      break
    case 'walkinRejected':
      statusMessage = `Host rejected this walk-in request due to reason ${data.reason}.`
      break
    default:
  }

  let title = `Visitor Pass | Request - ${data.visitor.name}`
  let visitDate = makeDisplayDate(new Date(data.visitDate))

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex flex-col space-y-2 mt-4 text-primary">
        <PageHeading title="Request Details" />
        <div className="flex flex-col sm:flex-row">
          {statusMessage ? null : (
            <img
              src={data.visitor.photoUrl}
              className="mb-2 sm:mr-2 rounded-xl w-48 h-48 object-cover"
              alt={data.visitor.name}
            />
          )}
          <div className="flex flex-col">
            <span className="text-xl font-semibold">{data.visitor.name}</span>
            <span className="font-normal">{data.visitor.company}</span>
            <span className="font-normal mt-4">{purposeMessage}</span>
            <span className="italic text-xs">
              {visitDate}
            </span>
            {statusMessage ? (
              <span className="mt-4 text-accent text-lg font-medium">
                {statusMessage}
              </span>
            ) : null}
            {data.status === 'visitStarted' ||
            data.status === 'visitCompleted' ? (
              <div>
                <button
                  className="p-2 bg-primary text-xs rounded-md text-slate-200"
                  onClick={goToVisit}
                >
                  View Visit
                </button>
              </div>
            ) : null}
            {!statusMessage ? (
              <div className="mt-4 text-sm">
                <button
                  className="p-2 bg-primary mr-2 rounded-md text-slate-200"
                  onClick={approve}
                >
                  Approve
                </button>
                <button
                  className="p-2 bg-accent rounded-md text-slate-200"
                  onClick={reject}
                >
                  Reject
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestDetails
