import React from 'react'
import UserService from '../utils/userService'

const LogoutButton = () => {
  const logout = () => {
    sessionStorage.clear()
    UserService.doLogout()
  }

  return (
    <button
      className="mx-2 px-3 py-1.5 rounded text-white text-sm bg-accent"
      onClick={logout}
    >
      Logout
    </button>
  )
}

export default LogoutButton
