import React from 'react'

import SxpLogo from '../assets/sxp-new-logo.png'

const Footer = () => {
  return (
    <footer className="h-10 p-2 border-t border-gray-200 hidden sm:flex flex-row space-x-2 justify-center items-center">
      <span className="text-primary text-sm">Powered by</span>
      <img src={SxpLogo} alt="SxP" width={40} />
    </footer>
  )
}

export default Footer
