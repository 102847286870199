import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import {
  fetchPendingVisitsAsync,
  selectAllVisitsStatus,
  selectVisits,
} from './visitSlice'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import RenderOnAuthenticated from '../../components/RenderOnAuthenticated'
import { useNavigate } from 'react-router-dom'
import RenderOnRole from '../../components/RenderOnRole'
import PageHeading from '../../components/PageHeading'
import { makeDisplayDate } from '../../utils/helper'
import { resetRequests } from '../request/requestSlice'
import { resetHome } from '../home/homeSlice'

const VisitStatus = ({ visits }) => {
  const navigate = useNavigate()

  const selectVisit = async (id) => {
    navigate(`/visits/${id}`)
  }

  return (
    <div>
      {visits?.length ? (
        <div className="flex flex-col justify-start items-center h-full space-y-4">
          <PageHeading title="Visits" />
          {visits.map((vst) => (
            <SingleVisit
              key={vst.objectId}
              visit={vst}
              onClick={() => selectVisit(vst.objectId)}
            />
          ))}
        </div>
      ) : (
        <PageHeading title="No Pending Visits" />
      )}
    </div>
  )
}

const SingleVisit = ({ visit, onClick }) => {
  return (
    <div
      className="flex flex-row min-w-full overflow-hidden bg-slate-100 rounded-xl cursor-pointer"
      onClick={onClick}
    >
      <div>
        <img
          className="object-cover w-24 h-24"
          src={visit.visitor.photoUrl}
          alt="Visitor"
        />
      </div>
      <div className="flex flex-col justify-between px-4 py-1.5 pr-6 text-left text-slate-700">
        <div className="flex flex-col">
          <span className="text-xl font-bold">{visit.visitor.name}</span>
          <span className="text-xs font-medium">{visit.request.type}</span>
        </div>
        <span className="text-xs italic">
          Checked In At {makeDisplayDate(visit.checkinTime, true)}
        </span>
      </div>
    </div>
  )
}

const Visits = () => {
  const allStatus = useSelector(selectAllVisitsStatus)
  const visits = useSelector(selectVisits)
  const dispatch = useDispatch()

  useEffect(() => {
    if (allStatus === 'idle') {
      setTimeout(() => {
        dispatch(fetchPendingVisitsAsync())
        dispatch(resetHome())
        dispatch(resetRequests())
      }, 500)
    }
  }, [allStatus, dispatch])

  let content
  if (allStatus === 'idle' || allStatus === 'loading') {
    content = <Loading />
  } else if (allStatus === 'failed') {
    content = <Error />
  } else if (allStatus === 'succeeded') {
    content = <VisitStatus visits={visits} />
  }

  return (
    <RenderOnAuthenticated>
      <RenderOnRole roles={['admin', 'security']}>
        <Helmet>
          <title>Visitor Pass | Visits</title>
        </Helmet>
        {content ?? null}
      </RenderOnRole>
    </RenderOnAuthenticated>
  )
}

export default Visits
