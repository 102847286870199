export const getStateObj = (
  choice,
  visitorName,
  hostCompany,
  hostCompanyLogo,
  hostName,
  hostEmail,
  visitorEmail,
  visitorMobile,
) => {
  let mailMessage
  let smsMessage
  if (choice === 'reject') {
    mailMessage = `Security has rejected the visit request for ${visitorName}.`
    smsMessage = `Your visit to ${hostCompany} is rejected by Security.`
  } else if (choice === 'approve') {
    mailMessage = `${visitorName} has checked in.`
    smsMessage = `Your visit to ${hostCompany} is started.`
  } else {
    mailMessage = `${visitorName} has checked out.`
    smsMessage = `Your visit to ${hostCompany} is completed.`
  }
  return {
    state: {
      companyLogoUrl: hostCompanyLogo,
      hostCompany: hostCompany,
      hostName: hostName,
      hostEmail: hostEmail,
      visitorEmail: visitorEmail,
      visitorMobile: visitorMobile,
      visitMailMessage: mailMessage,
      visitSMSMessage: smsMessage,
    },
  }
}
