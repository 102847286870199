import HttpService from '../../utils/httpService'

export const fetchOpenRequests = (date) => {
  const axios = HttpService.getApiClient()
  return axios.get(
    `/classes/Request?include=visitor&where={"visitDate":{"$gte": "${date}"}}&order=visitDate`,
  )
}

export const fetchRequestById = (id) => {
  const axios = HttpService.getApiClient()
  return axios.get(`/classes/Request/${id}?include=visitor`)
}

export const updateRequest = (id, body) => {
  const axios = HttpService.getApiClient()
  return axios.put(`/classes/Request/${id}`, body)
}
