import HttpService from '../../utils/httpService'
import { PRINT_URL } from '../../utils/constants'

export const createVisit = (body) => {
  const axios = HttpService.getApiClient()
  return axios.post('/classes/Visit', body)
}

export const fetchVisitById = (id) => {
  const axios = HttpService.getApiClient()
  return axios.get(`/classes/Visit/${id}?include=visitor,request`)
}

export const updateVisit = (id, body) => {
  const axios = HttpService.getApiClient()
  return axios.put(`/classes/Visit/${id}`, body)
}

export const fetchVisitByRequestId = (id) => {
  const axios = HttpService.getApiClient()
  return axios.get(
    `/classes/Visit?where={"request":{"__type":"Pointer","className":"Request","objectId":"${id}"}}&include=request,visitor`,
  )
}

export const fetchPendingVisits = () => {
  const axios = HttpService.getApiClient()
  return axios.get(
    `/classes/Visit?where={"checkoutTime":{"$exists":false}}&include=request,visitor&limit=5`,
  )
}

export const printVisitorPass = (body) => {
  const axios = HttpService.getApiClient()
  return axios.post('/print', body, {
    baseURL: PRINT_URL,
  })
}
