export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

export const makeDisplayDate = (str, exact = false) => {
  const date = new Date(str)
  if (!exact) {
    date.setHours(date.getHours() - 5)
    date.setMinutes(date.getMinutes() - 30)
  }
  return Intl.DateTimeFormat('en-IN', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(date)
}
