import React from 'react'

const avatarName = (name) => {
  if (!name || name.trim().length === 0) {
    throw new Error('Name should be valid')
  }
  const splitName = name.split(' ').map((s) => s.charAt(0).toUpperCase())
  if (splitName.length > 1) {
    return splitName[0] + splitName[1]
  } else {
    return splitName[0]
  }
}

const Avatar = ({ photoUrl, hostName }) => {
  if (photoUrl) {
    return (
      <img
        src={photoUrl}
        alt={hostName}
        className="w-10 h-10 sm:w-12 sm:h-12 rounded-full object-cover"
      />
    )
  }
  return (
    <div className="w-10 h-10 sm:w-12 sm:h-12 bg-primary text-slate-200 rounded-full sm:text-xl flex justify-center items-center">
      {avatarName(hostName)}
    </div>
  )
}

export default Avatar
