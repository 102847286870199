import React from 'react'
import Avatar from '../../components/Avatar'
import { MdOutlineEmail } from 'react-icons/md'
import { MdLocalPhone } from 'react-icons/md'

import { makeDisplayDate } from '../../utils/helper'
import { restartJourney, updateState } from './chatAPI'
import UserService from '../../utils/userService'

const RequestCard = ({ request }) => {
  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1)

  const splitCamel = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1')
    const finalResult = capitalize(result)
    return finalResult.trim()
  }

  const updateDisabled = !(
    request.status === 'hostRequested' ||
    request.status === 'walkinRequested' ||
    request.status === 'rescheduleRequested'
  )
  const cancelDisabled = !(
    request.status === 'hostRequested' ||
    request.status === 'walkinRequested' ||
    request.status === 'rescheduleRequested'
  )

  const update = async () => {
    const action =
      request.status === 'hostRequested'
        ? 'update'
        : request.status === 'walkinRequested'
        ? 'approve'
        : 'acceptReschedule'
    const body = {
      state: {
        serviceType: 'viewRequests',
        skipWalkinCheck: 'yes',
        hostRequest: request.objectId,
        requestAction: action,
      },
    }
    try {
      await restartJourney()
      await updateState(body)
      // eslint-disable-next-line no-undef
      chatSendMsg('hi', UserService.getUsername())
    } catch (err) {
      console.log(err)
    }
  }

  const cancel = async () => {
    const action =
      request.status === 'hostRequested'
        ? 'cancel'
        : request.status === 'walkinRequested'
        ? 'reject'
        : 'rejectReschedule'
    const body = {
      state: {
        serviceType: 'viewRequests',
        skipWalkinCheck: 'yes',
        hostRequest: request.objectId,
        requestAction: action,
      },
    }
    try {
      await restartJourney()
      await updateState(body)
      // eslint-disable-next-line no-undef
      chatSendMsg('hi', UserService.getUsername())
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="flex flex-row items-center space-x-2 border border-slate-200 rounded-md px-3 py-1.5">
      <div className="min-w-fit">
        <Avatar
          photoUrl={request.visitor.photoUrl}
          hostName={request.visitor.name}
        />
      </div>
      <div className="px-1 min-w-fit w-52">
        <div className="flex flex-row space-x-1 items-baseline">
          <p className="font-semibold text-primary">{request.visitor.name}</p>
          <p className="text-[.675rem] text-slate-500">
            {request.visitor.company}
          </p>
        </div>
        <div className="flex flex-row space-x-1 items-baseline text-slate-500 text-xs">
          <MdOutlineEmail />
          <p>{request.visitor.email}</p>
        </div>
        <div className="flex flex-row space-x-1 items-baseline text-slate-500 text-xs">
          <MdLocalPhone />
          <p>{request.visitor.mobile}</p>
        </div>
      </div>
      <div className="px-1 min-w-fit w-48">
        <p className="text-amber-700 font-semibold">
          {splitCamel(request.status)}
        </p>
        <p className="text-slate-500 text-xs">{capitalize(request.purpose)}</p>
        <p className="text-slate-500 text-xs">
          {makeDisplayDate(request.visitDate)}
        </p>
      </div>
      <div className="flex flex-col space-y-2 justify-center items-center w-14 px-4">
        <button
          className={`px-2 py-1 bg-primary text-slate-200 text-[.675rem] rounded-full ${
            updateDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          disabled={updateDisabled}
          onClick={update}
        >
          {request.status === 'walkinRequested'
            ? 'Approve'
            : request.status === 'rescheduleRequested'
            ? 'Accept'
            : 'Update'}
        </button>
        <button
          className={`px-2 py-1 bg-primary text-slate-200 text-[.675rem] rounded-full ${
            cancelDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          disabled={cancelDisabled}
          onClick={cancel}
        >
          {request.status === 'walkinRequested' ||
          request.status === 'rescheduleRequested'
            ? 'Reject'
            : 'Cancel'}
        </button>
      </div>
    </div>
  )
}

export default RequestCard
