import HttpService from '../../utils/httpService'

export const fetchLatestUuid = () => {
  const axios = HttpService.getApiClient()
  return axios.get(`/classes/Uuid?order=-expires&limit=1`)
}

export const createUuid = (body) => {
  const axios = HttpService.getApiClient()
  return axios.post(`/classes/Uuid`, body)
}
