import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectUserId } from '../app/appSlice'
import UserService from '../../utils/userService'
import {
  selectVisitRequestStatus,
  startVisitorJourneyAsync,
} from './visitRequestSlice'
import { Helmet } from 'react-helmet'

const VisitRequest = () => {
  const { requestId } = useParams()
  const userId = useSelector(selectUserId)
  const visitRequestStatus = useSelector(selectVisitRequestStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!UserService.isLoggedIn() && userId && visitRequestStatus === 'idle') {
      const state = {
        requestId: requestId,
      }
      dispatch(
        startVisitorJourneyAsync({ state, message: 'visitRequest', userId }),
      )
    }
  }, [userId, requestId, dispatch, visitRequestStatus])

  return (
    <>
      <Helmet>
        <title>Visitor Pass | Request - {requestId}</title>
      </Helmet>
      <div>
        <h2 className="text-primary text-xl font-black">
          Welcome to Visitor Pass
        </h2>
      </div>
    </>
  )
}

export default VisitRequest
