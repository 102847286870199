import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import RenderOnAuthenticated from '../../components/RenderOnAuthenticated'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import {
  fetchRequestByIdAsync,
  selectRequestStatus,
} from './requestSlice'
import RequestDetails from './RequestDetails'
import RenderOnRole from '../../components/RenderOnRole'

const Request = () => {
  const params = useParams()
  const status = useSelector(selectRequestStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === 'idle') {
      setTimeout(() => {
        dispatch(fetchRequestByIdAsync(params.requestId))
      }, 500)
    }
  }, [status, params.requestId, dispatch])

  let content

  if (status === 'idle' || status === 'loading') {
    content = <Loading />
  } else if (status === 'failed') {
    content = <Error />
  } else if (status === 'succeeded') {
    content = <RequestDetails />
  }

  return (
    <RenderOnAuthenticated>
      <RenderOnRole roles={['admin', 'security']}>{content}</RenderOnRole>
    </RenderOnAuthenticated>
  )
}

export default Request
