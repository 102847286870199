import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  endVisitAsync,
  printPassAsync,
  selectPrintStatus,
  selectVisitData,
} from './visitSlice'
import { useLocation } from 'react-router-dom'
import { APP_URL } from '../../utils/constants'
import { prepareZpl } from './utils/printUtil'
import { Helmet } from 'react-helmet'
import PrintStatus from './PrintStatus'
import PageHeading from '../../components/PageHeading'
import { makeDisplayDate } from '../../utils/helper'

const VisitDetails = () => {
  const { pathname } = useLocation()
  const data = useSelector(selectVisitData)
  const printStatus = useSelector(selectPrintStatus)
  const dispatch = useDispatch()

  const print = async () => {
    const url = `${APP_URL}${pathname}`
    const hostName = data.host.name.split(" ")[0]
    const checkinTime = data.checkinTime
    const checkoutTime = new Date(checkinTime)
    checkoutTime.setHours(checkoutTime.getHours() + 1)
    const zpl = prepareZpl(
      url,
      data.visitor.name,
      data.host.company,
      hostName.substring(0,8),
      makeDisplayDate(checkinTime, true),
      makeDisplayDate(checkoutTime, true),
    )
    const body = { zpl }
    dispatch(printPassAsync(body))
  }

  const endVisit = () => {
    dispatch(endVisitAsync())
  }

  let checkinTime = data.checkinTime
    ? makeDisplayDate(data.checkinTime, true)
    : ''
  let checkoutTime = data.checkoutTime
    ? makeDisplayDate(data.checkoutTime, true)
    : ''

  const title = `Visitor Pass | Visit - ${data.visitor.name}`
  const username = `Visit - ${data.visitor.name}`

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="text-primary">
        <div className="mb-2">
          <PageHeading title={username} />
          {data.checkinTime && !data.checkoutTime ? (
            <span className="text-xs italic">Checked-in at {checkinTime}</span>
          ) : null}
          {data.checkoutTime ? (
            <span className="text-xs italic">
              Checked-out at {checkoutTime}
            </span>
          ) : null}
        </div>
        {!data.checkoutTime ? (
          <div>
            <button
              className={`bg-primary mr-2 text-slate-200 px-2 py-1 rounded text-sm ${
                printStatus === 'idle' ? 'opacity-100' : 'opacity-60'
              }`}
              onClick={print}
              disabled={printStatus !== 'idle'}
            >
              Print
            </button>
            <button
              className={`bg-accent text-slate-200 px-2 py-1 rounded text-sm ${
                printStatus === 'idle' ? 'opacity-100' : 'opacity-60'
              }`}
              onClick={endVisit}
              disabled={printStatus !== 'idle'}
            >
              Check-out
            </button>
          </div>
        ) : null}
        <PrintStatus />
      </div>
    </>
  )
}

export default VisitDetails
