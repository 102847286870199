import React from 'react'

import UserService from '../utils/userService'

const LoginButton = () => {
  const login = () => {
    UserService.doLogin()
  }

  return (
    <button
      className="mx-2 px-3 py-1.5 rounded text-slate-200 text-sm bg-primary"
      onClick={login}
    >
      Login
    </button>
  )
}

export default LoginButton
