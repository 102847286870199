// AUTH RELATED
export const AUTH_URL =
  process.env.REACT_APP_AUTH_URL ||
  'https://sxp.keycloak-dev.techsophy.com/auth'
export const REALM = process.env.REACT_APP_REALM || 'sxp-sit'
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || 'sxp'

// SOCKET RELATED
export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL ||
  'https://sxp.channel-adapter.sit.techsophy.com/'
export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH || '/socket.io/'
export const FILE_SERVER_URL =
  process.env.REACT_APP_FILE_SERVER_URL || 'https://fileserver.techsophy.com/'
export const PROJECT_ID =
  process.env.REACT_APP_PROJECT_ID || '62b94d31eb1bb9513de77ddd'
export const VERSION = process.env.REACT_APP_VERSION || 'WORKING'

// PARSE
export const PARSE_URL =
  process.env.REACT_APP_PARSE_URL ||
  'https://sxp.parse-server.sit.techsophy.com/app/62b94d31eb1bb9513de77ddd'

// PRINT
export const APP_URL =
  process.env.REACT_APP_APP_URL || 'https://jolly-sundae-bfff39.netlify.app'
export const PRINT_URL =
  process.env.REACT_APP_PRINT_URL || 'https://172.16.0.242:3000'

// QR
export const QR_URL =
  process.env.REACT_APP_QR_URL || 'https://sxp-utils.techsophy.com'
