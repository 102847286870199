import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  fetchOpenRequestsAsync,
  selectAllRequestsStatus,
  selectRequests,
} from './requestSlice'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import RenderOnRole from '../../components/RenderOnRole'
import RenderOnAuthenticated from '../../components/RenderOnAuthenticated'
import PageHeading from '../../components/PageHeading'
import { makeDisplayDate } from '../../utils/helper'
import { resetVisits } from '../visit/visitSlice'
import { resetHome } from '../home/homeSlice'

const RequestStatus = ({ requests }) => {
  const navigate = useNavigate()

  const selectRequest = async (id) => {
    navigate(`/requests/${id}`)
  }

  const today = new Date().toISOString().slice(0, 10);
  const filtered = requests.filter(req => {
    const visitDate = req.visitDate.slice(0, 10);
    return visitDate >= today;
  });

  return (
    <div>
      {filtered?.length ? (
        <div className="flex flex-col justify-start items-center h-full space-y-4">
          <PageHeading title="Requests" />
          {filtered.map((req) => (
            <SingleRequest
              key={req.objectId}
              request={req}
              onClick={() => selectRequest(req.objectId)}
            />
          ))}
        </div>
      ) : (
        <PageHeading title="No Open Requests" />
      )}
    </div>
  )
}

const SingleRequest = ({ request, onClick }) => {
  return (
    <div
      className="flex flex-row min-w-full overflow-hidden bg-slate-100 rounded-xl cursor-pointer"
      onClick={onClick}
    >
      <div>
        <img
          className="object-cover w-24 h-24"
          src={request.visitor.photoUrl}
          alt={request.visitor.name}
        />
      </div>
      <div className="flex flex-col justify-between px-4 py-1.5 pr-6 text-left text-slate-700">
        <div className="flex flex-col">
          <span className="text-xl font-bold">{request.visitor.name}</span>
          <span className="text-xs font-medium">{request.type}</span>
        </div>
        <span className="text-xs italic">
          {makeDisplayDate(request.visitDate)}
        </span>
      </div>
    </div>
  )
}

const Requests = () => {
  const allStatus = useSelector(selectAllRequestsStatus)
  const requests = useSelector(selectRequests)
  const dispatch = useDispatch()

  useEffect(() => {
    if (allStatus === 'idle') {
      setTimeout(() => {
        dispatch(fetchOpenRequestsAsync())
        dispatch(resetVisits())
        dispatch(resetHome())
      }, 500)
    }
  }, [allStatus, dispatch])

  let content
  if (allStatus === 'idle' || allStatus === 'loading') {
    content = <Loading />
  } else if (allStatus === 'failed') {
    content = <Error />
  } else if (allStatus === 'succeeded') {
    content = <RequestStatus requests={requests} />
  }

  return (
    <RenderOnAuthenticated>
      <RenderOnRole roles={['admin', 'security']}>
        <Helmet>
          <title>Visitor Pass | Requests</title>
        </Helmet>
        {content ?? null}
      </RenderOnRole>
    </RenderOnAuthenticated>
  )
}

export default Requests
