import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { MdOutlineAdd } from 'react-icons/md'

import UserService from '../../utils/userService'
import {
  fetchHostRequestsAsync,
  selectHostRequests,
  selectHostStatus,
} from './homeSlice'
import PageHeading from '../../components/PageHeading'
import Loading from '../../components/Loading'
import RequestCard from './RequestCard'
import { resetVisits } from '../visit/visitSlice'
import { resetRequests } from '../request/requestSlice'
import { restartJourney, updateState } from './chatAPI'

const Home = () => {
  const hostStatus = useSelector(selectHostStatus)
  const hostRequests = useSelector(selectHostRequests)
  const dispatch = useDispatch()

  useEffect(() => {
    if (UserService.isLoggedIn() && hostStatus === 'idle') {
      dispatch(fetchHostRequestsAsync())
      dispatch(resetVisits())
      dispatch(resetRequests())
    }
  }, [hostStatus, dispatch])

  const createRequest = async () => {
    const body = {
      state: {
        serviceType: 'newRequest',
      },
    }
    try {
      await restartJourney()
      await updateState(body)
      // eslint-disable-next-line no-undef
      chatSendMsg('hi', UserService.getUsername())
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Helmet>
        <title>Visitor Pass | Home</title>
      </Helmet>
      {hostStatus === 'loading' ? (
        <Loading />
      ) : (
        <div className="flex flex-col h-full w-full justify-start items-center space-y-2">
          {UserService.isLoggedIn() ? (
            <>
              <div className="flex flex-row justify-center items-center relative w-full">
                <PageHeading title="Requests" />
                <button
                  className="absolute right-0 bg-primary p-1 text-slate-200 rounded-full text-xs mr-2 flex flex-row items-center space-x-1"
                  onClick={createRequest}
                >
                  <MdOutlineAdd className="text-xl" />
                  <span className="hidden sm:block pr-2 text-sm">Create</span>
                </button>
              </div>
              {hostRequests.length ? (
                hostRequests.map((hr) => (
                  <div key={hr.objectId}>
                    <RequestCard request={hr} />
                  </div>
                ))
              ) : (
                <div className="px-10 py-2 text-primary bg-slate-100 rounded-lg">
                  You have not made any requests yet
                </div>
              )}
            </>
          ) : (
            <>
              <p className="mt-20 text-3xl text-primary font-black">Welcome</p>
              <p className="text-primary">
                If you are a host login to create a new request
              </p>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Home
