import React from 'react'

import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen min-h-screen">
      <Header />
      <div className="flex-1 overflow-y-auto p-2 items-center no-scrollbar mx-auto">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
