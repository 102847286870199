import Keycloak from 'keycloak-js'
import { AUTH_URL, CLIENT_ID, REALM } from './constants'

const storeToken = (token) => {
  console.log('storing token in session storage')
  sessionStorage.setItem('react-token', token ?? '')
}

const config = {
  url: AUTH_URL,
  realm: REALM,
  clientId: CLIENT_ID,
}

const _kc = new Keycloak(config)

const initKeycloak = (init) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      console.log('AUTHENTICATED >>>', authenticated)
      init()
    })
    .catch((err) => {
      console.log(err)
    })
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback) => _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const getEmail = () => _kc.tokenParsed?.email

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role) || _kc.hasResourceRole(role))

_kc.onTokenExpired = () => {
  _kc.updateToken(-1)
}

_kc.onAuthSuccess = () => {
  storeToken(_kc.token)
}

_kc.onAuthRefreshSuccess = () => {
  storeToken(_kc.token)
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getEmail,
  hasRole,
}

export default UserService
