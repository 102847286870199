import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fetchPendingVisits,
  fetchVisitById,
  printVisitorPass,
  updateVisit,
} from './visitAPI'
import { updateRequest } from '../request/requestAPI'
import { getStateObj } from './utils/journeyUtil'
import { startJourney, updateState } from '../home/chatAPI'

const initialState = {
  status: 'idle',
  printStatus: 'idle',
  data: {
    objectId: '',
    host: {
      name: '',
      mobile: '',
      email: '',
      department: '',
      company: '',
      companyLogo: '',
    },
    visitor: {
      objectId: '',
      name: '',
      email: '',
      mobile: '',
      company: '',
    },
    request: {
      objectId: '',
      type: '',
      purpose: '',
      status: '',
    },
    checkinTime: '',
    checkoutTime: '',
    qrCodeUrl: '',
  },
  allStatus: 'idle',
  visits: [],
}

export const fetchPendingVisitsAsync = createAsyncThunk(
  'visit/fetchPendingVisits',
  async () => {
    const response = await fetchPendingVisits()
    return response.data
  },
)

export const fetchVisitByIdAsync = createAsyncThunk(
  'visit/fetchVisitById',
  async (id) => {
    const response = await fetchVisitById(id)
    return response.data
  },
)

export const printPassAsync = createAsyncThunk(
  'visit/printPass',
  async (body, { dispatch }) => {
    await dispatch(startPrinter())
    const printResponse = await printVisitorPass(body)
    return printResponse.data
  },
)

export const endVisitAsync = createAsyncThunk(
  'visit/endVisit',
  async (_, { getState, dispatch }) => {
    const visit = selectVisitData(getState())
    const now = new Date().toISOString()
    const visitBody = {
      checkoutTime: now,
    }
    const visitResponse = await updateVisit(visit.objectId, visitBody)
    const requestBody = {
      status: 'visitCompleted',
    }
    await updateRequest(visit.request.objectId, requestBody)
    await dispatch(startVisitOptionsAsync('checkout'))
    return visitResponse.data
  },
)

export const startVisitOptionsAsync = createAsyncThunk(
  'request/startVisitOptions',
  async (choice, { getState }) => {
    const visitData = selectVisitData(getState())
    const stateObj = getStateObj(
      choice,
      visitData.visitor.name,
      visitData.host.company,
      visitData.host.companyLogo,
      visitData.host.name,
      visitData.host.email,
      visitData.visitor.email,
      visitData.visitor.mobile,
    )
    await updateState(stateObj)
    await startJourney()
  },
)

export const visitSlice = createSlice({
  name: 'visit',
  initialState,
  reducers: {
    startPrinter: (state) => {
      state.printStatus = 'loading'
    },
    hidePrintStatus: (state) => {
      state.printStatus = 'idle'
    },
    resetVisits: state => {
      state.allStatus = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingVisitsAsync.pending, (state) => {
        state.allStatus = 'loading'
        state.status = 'idle'
      })
      .addCase(fetchPendingVisitsAsync.fulfilled, (state, action) => {
        state.allStatus = 'succeeded'
        state.visits = action.payload.results
      })
      .addCase(fetchPendingVisitsAsync.rejected, (state) => {
        state.allStatus = 'failed'
      })
      .addCase(fetchVisitByIdAsync.pending, (state) => {
        state.status = 'loading'
        state.allStatus = 'idle'
      })
      .addCase(fetchVisitByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchVisitByIdAsync.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(endVisitAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(endVisitAsync.fulfilled, (state) => {
        state.visits = state.visits.filter(
          (vs) => vs.objectId !== state.data.objectId,
        )
        state.status = 'idle'
      })
      .addCase(endVisitAsync.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(printPassAsync.fulfilled, (state) => {
        state.printStatus = 'succeeded'
      })
      .addCase(printPassAsync.rejected, (state) => {
        state.printStatus = 'failed'
      })
  },
})

export const { resetVisits, startPrinter, hidePrintStatus } = visitSlice.actions

export const selectVisitStatus = (state) => state.visit.status
export const selectAllVisitsStatus = (state) => state.visit.allStatus
export const selectVisitData = (state) => state.visit.data
export const selectVisits = (state) => state.visit.visits
export const selectPrintStatus = (state) => state.visit.printStatus

export default visitSlice.reducer
