import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";

import { store } from './app/store'
import App from './App'
import './index.css'
import UserService from './utils/userService'
import HttpService from './utils/httpService'

const container = document.getElementById('root')
const root = createRoot(container)
const renderRoot = () => {
  root.render(
    // <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </Provider>
    // </React.StrictMode>,
  )
}

UserService.initKeycloak(renderRoot)
HttpService.configure()
