import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import RenderOnAuthenticated from '../../components/RenderOnAuthenticated'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import { fetchVisitByIdAsync, selectVisitStatus } from './visitSlice'
import VisitDetails from './VisitDetails'
import RenderOnRole from '../../components/RenderOnRole'

const Visit = () => {
  const params = useParams()
  const status = useSelector(selectVisitStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === 'idle') {
      setTimeout(() => {
        dispatch(fetchVisitByIdAsync(params.visitId))
      }, 500)
    }
  }, [status, params.visitId, dispatch])

  let content
  if (status === 'idle' || status === 'loading') {
    content = <Loading />
  } else if (status === 'failed') {
    content = <Error />
  } else if (status === 'succeeded') {
    content = <VisitDetails />
  }

  return (
    <RenderOnAuthenticated>
      <RenderOnRole roles={['admin', 'security']}>
        {content ?? null}
      </RenderOnRole>
    </RenderOnAuthenticated>
  )
}

export default Visit
