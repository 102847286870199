import HttpService from '../../utils/httpService'
import UserService from "../../utils/userService";

export const fetchHostRequests = () => {
  const apiClient = HttpService.getApiClient()
  const hostEmail = UserService.getEmail()
  return apiClient.get(
    `/classes/Request?include=visitor&where={"host.email":"${hostEmail}"}`,
  )
}
