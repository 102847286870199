import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { fetchOpenRequests, fetchRequestById, updateRequest } from "./requestAPI";
import { createVisit, fetchVisitByRequestId } from "../visit/visitAPI";
import { getStateObj } from '../visit/utils/journeyUtil'
import { startJourney, updateState } from '../home/chatAPI'

const initialState = {
  status: 'idle',
  data: {
    objectId: '',
    type: '',
    host: {
      name: '',
      mobile: '',
      email: '',
      department: '',
      company: '',
      companyLogo: '',
    },
    visitor: {
      objectId: '',
      name: '',
      email: '',
      mobile: '',
      company: '',
    },
    purpose: '',
    visitDate: '',
    visitTime: '',
    status: '',
    reason: '',
    photoUrl: '',
    qrCodeUrl: '',
    visitId: '',
  },
  allStatus: 'idle',
  requests: [],
}

export const fetchOpenRequestsAsync = createAsyncThunk(
  'request/fetchOpenRequests',
  async () => {
    const date = new Date().toISOString().slice(0, 10)
    const response = await fetchOpenRequests(date)
    return response.data
  },
)

export const fetchRequestByIdAsync = createAsyncThunk(
  'request/fetchRequestById',
  async (id) => {
    const response = await fetchRequestById(id)
    if (
      response.data.status === 'visitStarted' ||
      response.data.status === 'visitCompleted'
    ) {
      const visitResponse = await fetchVisitByRequestId(id)
      const visit = visitResponse.data.results[0]
      if (visit) {
        response.data.visitId = visit.objectId
      }
    }
    return response.data
  },
)

export const approveRequestAsync = createAsyncThunk(
  'request/approveRequest',
  async (_, { getState, dispatch }) => {
    const request = selectRequestData(getState())
    const now = new Date().toISOString()
    const visitBody = {
      host: request.host,
      visitor: {
        __type: 'Pointer',
        className: 'Visitor',
        objectId: request.visitor.objectId,
      },
      request: {
        __type: 'Pointer',
        className: 'Request',
        objectId: request.objectId,
      },
      checkinTime: now,
    }
    try {
      const visitResponse = await createVisit(visitBody)
      const reqBody = {
        status: 'visitStarted',
      }
      await updateRequest(request.objectId, reqBody)
      await dispatch(startRequestOptionsAsync('approve'))
      return visitResponse.data
    } catch (err) {
      console.log(err)
    }
  },
)

export const rejectRequestAsync = createAsyncThunk(
  'request/rejectRequest',
  async (_, { getState, dispatch }) => {
    const request = selectRequestData(getState())
    const reqBody = {
      status: 'securityRejected',
    }
    const response = await updateRequest(request.objectId, reqBody)
    await dispatch(startRequestOptionsAsync('reject'))
    return response.data
  },
)

export const startRequestOptionsAsync = createAsyncThunk(
  'request/startRequestOptions',
  async (choice, { getState }) => {
    const request = selectRequestData(getState())
    const stateObj = getStateObj(
      choice,
      request.visitor.name,
      request.host.company,
      request.host.companyLogo,
      request.host.name,
      request.host.email,
      request.visitor.email,
      request.visitor.mobile,
    )
    await updateState(stateObj)
    await startJourney()
  },
)

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    resetRequests: state => {
      state.allStatus = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpenRequestsAsync.pending, (state) => {
        state.allStatus = 'loading'
        state.status = 'idle'
      })
      .addCase(fetchOpenRequestsAsync.fulfilled, (state, action) => {
        state.allStatus = 'succeeded'
        state.requests = action.payload.results
      })
      .addCase(fetchOpenRequestsAsync.rejected, (state) => {
        state.allStatus = 'failed'
        state.requests = []
      })
      .addCase(fetchRequestByIdAsync.pending, (state) => {
        state.status = 'loading'
        state.allStatus = 'idle'
      })
      .addCase(fetchRequestByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchRequestByIdAsync.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(approveRequestAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(approveRequestAsync.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(rejectRequestAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(rejectRequestAsync.fulfilled, (state) => {
        state.status = 'idle'
      })
  },
})

export const { resetRequests } = requestSlice.actions

export const selectRequestStatus = (state) => state.request.status
export const selectAllRequestsStatus = (state) => state.request.allStatus
export const selectRequestData = (state) => state.request.data
export const selectRequests = (state) => state.request.requests

export default requestSlice.reducer
