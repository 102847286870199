export const prepareZpl = (
  visitUrl,
  visitorName,
  company,
  hostName,
  issuedOn,
  validTill,
) => `^XA
^FO10,12
^BQN,2,7
^FDQA,${visitUrl}^FS
^CF0,50,50
^FO300,20^FD${visitorName}^FS
^CF0,35,35
^FO300,75^FDTo Meet:^FS
^CFA,30,15
^FO440,80^FD${hostName}(${company})^FS
^CF0,30,30
^FO300,140^FDIssued On^FS
^CFF,12,10
^FO300,170^FD${issuedOn}^FS
^CF0,30,30
^FO300,220^FDValid Till^FS
^CFF,12,10
^FO300,250^FD${validTill}^FS
^XZ`
