import React, { useEffect, useState } from 'react'

const CountdownTimer = ({ time, callExpired }) => {
  const [count, setCount] = useState(time)

  useEffect(() => {
    if (count > 0) {
      const intervalId = setInterval(() => {
        setCount((c) => c - 1)
      }, 1000)
      return () => clearInterval(intervalId)
    } else {
      callExpired()
    }
  }, [count, callExpired])

  const minutes = Math.floor(count / 60)
  const seconds = count % 60

  return (
    <div className="flex flex-col items-center">
      <p className="font-semibold text-primary">Expires in</p>
      <div
        className={`flex flex-row items-center ${
          count < 60 ? 'text-orange-500' : 'text-primary'
        }`}
      >
        <span className="font-mono text-xl font-bold px-2 py-1 bg-slate-100 rounded-md">
          {minutes > 9 ? minutes : `0${minutes}`}
        </span>
        <span className="font-mono text-xl font-bold">:</span>
        <span className="font-mono text-xl font-bold px-2 py-1 bg-slate-100 rounded-md">
          {seconds > 9 ? seconds : `0${seconds}`}
        </span>
      </div>
    </div>
  )
}

export default CountdownTimer
