import { configureStore } from '@reduxjs/toolkit'

import appReducer from '../features/app/appSlice'
import homeReducer from '../features/home/homeSlice'
import requestReducer from '../features/request/requestSlice'
import visitReducer from '../features/visit/visitSlice'
import visitRequestReducer from '../features/visitRequest/visitRequestSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    home: homeReducer,
    request: requestReducer,
    visit: visitReducer,
    visitRequest: visitRequestReducer,
  },
})
