import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchHostRequests } from './hostAPI'

const initialState = {
  status: 'idle',
  requests: [],
}

export const fetchHostRequestsAsync = createAsyncThunk(
  'home/fetchHostRequests',
  async () => {
    const response = await fetchHostRequests()
    return response.data
  },
)

export const homeSlice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    resetHome: state => {
      state.status = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHostRequestsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchHostRequestsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.requests = action.payload.results
      })
      .addCase(fetchHostRequestsAsync.rejected, (state) => {
        state.status = 'failed'
        state.requests = []
      })
  },
})

export const { resetHome } = homeSlice.actions

export const selectHostStatus = (state) => state.home.status
export const selectHostRequests = (state) => state.home.requests

export default homeSlice.reducer
