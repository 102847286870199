import HttpService from '../../utils/httpService'
import { SOCKET_URL, PROJECT_ID } from '../../utils/constants'

export const updateState = (body) => {
  const channelClient = HttpService.getApiClient()
  return channelClient.put(`/state/projectId/${PROJECT_ID}`, body, {
    baseURL: SOCKET_URL,
  })
}

export const restartJourney = (body) => {
  if (!body) {
    body = {
      message: '::restart',
      version: 'WORKING',
    }
  }
  const channelClient = HttpService.getApiClient()
  return channelClient.post(`/message/process/projectId/${PROJECT_ID}`, body, {
    baseURL: SOCKET_URL,
  })
}

export const startJourney = (body) => {
  if (!body) {
    body = {
      message: 'visitActions',
      version: 'WORKING',
    }
  }
  const channelClient = HttpService.getApiClient()
  return channelClient.post(`/message/process/projectId/${PROJECT_ID}`, body, {
    baseURL: SOCKET_URL,
  })
}

export const updateVisitorState = (userId, state) => {
  const body = {
    userId: userId,
    state: state,
  }
  const channelClient = HttpService.getPublicClient()
  return channelClient.put(`/state/projectId/${PROJECT_ID}`, body)
}
