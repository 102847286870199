import React from 'react'

import { MdOutlineBlock } from 'react-icons/md'
import { Helmet } from 'react-helmet'

const UnAuthorized = ({ isLoggedIn = false }) => {
  return (
    <>
      <Helmet>
        <title>Visitor Pass | Unauthorized</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center h-full space-y-4 px-4">
        <MdOutlineBlock size={60} className="text-red-500" />
        <span className="text-primary text-sm sm:text-base">
          {isLoggedIn
            ? 'You do not have permissions to access this route'
            : 'You are not authorized to access this route. Please login to access'}
        </span>
      </div>
    </>
  )
}

export default UnAuthorized
