import React from 'react'

import RenderOnAuthenticated from '../../components/RenderOnAuthenticated'
import { Helmet } from 'react-helmet'
import RenderOnRole from '../../components/RenderOnRole'
import PageHeading from '../../components/PageHeading'

const Reports = () => {
  return (
    <RenderOnAuthenticated>
      <RenderOnRole roles={['admin']}>
        <Helmet>
          <title>Visitor Pass | Reports</title>
        </Helmet>
        <PageHeading title="Reports" />
        <div className="h-[calc(100vh-8.5rem)]">
          <iframe
            id="superset"
            title="Reports Dashboard"
            src="https://sxp.superset.techsophy.com/superset/dashboard/4/?standalone=true"
            className="w-[calc(100vw-1rem)] h-full"
          ></iframe>
        </div>
      </RenderOnRole>
    </RenderOnAuthenticated>
  )
}

export default Reports
