import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hidePrintStatus, selectPrintStatus } from './visitSlice'
import { PRINT_URL } from '../../utils/constants'

const PrintStatus = () => {
  const printStatus = useSelector(selectPrintStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    if (printStatus === 'succeeded' || printStatus === 'failed') {
      const timeOutId = setTimeout(() => {
        dispatch(hidePrintStatus())
      }, 5000)
      return () => clearTimeout(timeOutId)
    }
  }, [printStatus, dispatch])

  const printText =
    printStatus === 'loading'
      ? 'Connecting to Printer...'
      : printStatus === 'succeeded'
      ? 'Visitor Pass Printed'
      : printStatus === 'failed'
      ? 'Failed to print'
      : ''

  return printStatus === 'idle' ? null : (
    <div
      className={`max-w-[13rem] p-4 mt-2 rounded text-slate-200 ${
        printStatus === 'succeeded' ? 'bg-green-600' : ''
      } ${printStatus === 'failed' ? 'bg-red-600' : ''} ${
        printStatus === 'loading' ? 'bg-primary' : ''
      }`}
    >
      <span className={`text-sm font-bold`}>{printText}</span>
      {printStatus === 'failed' ? (
        <>
          <p className="text-xs">
            Please make sure the printer is turned on and you can access it
            through network
          </p>
          <p className="mt-2 text-xs">
            <a
              className="underline"
              href={PRINT_URL}
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>{' '}
            to accept the certs if you have not done already
          </p>
        </>
      ) : null}
    </div>
  )
}

export default PrintStatus
