import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import UserService from '../../utils/userService'
import { selectUserId } from '../app/appSlice'
import { updateVisitorState } from '../home/chatAPI'
import { Helmet } from "react-helmet";

const Walkin = () => {
  const { uuid } = useParams()
  const userId = useSelector(selectUserId)

  useEffect(() => {
    const startJourney = async () => {
      const state = {
        uuid: uuid,
      }
      await updateVisitorState(userId, state)
      // eslint-disable-next-line no-undef
      chatSendMsg('walkin', userId)
    }
    if (!UserService.isLoggedIn() && userId) {
      startJourney()
        .then((_) => console.log('Journey started...'))
        .catch((err) => console.log(err))
    }
  }, [userId, uuid])

  return (
    <>
      <Helmet>
        <title>Visitor Pass | Walkin</title>
      </Helmet>
      <div>
        <h2 className="text-primary text-xl font-black">
          Welcome to Visitor Pass
        </h2>
      </div>
    </>
  )
}

export default Walkin
