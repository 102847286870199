import React from 'react'

const InvalidQr = ({ handleClick }) => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <p className="font-semibold text-primary text-center">
        No Valid Walkin link available
      </p>
      <button
        className="bg-primary rounded-md text-slate-200 px-2 py-1 mt-2 text-sm"
        onClick={handleClick}
      >
        Generate
      </button>
    </div>
  )
}

export default InvalidQr
