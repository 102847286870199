import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import PageHeading from '../../components/PageHeading'
import RenderOnRole from '../../components/RenderOnRole'
import RenderOnAuthenticated from '../../components/RenderOnAuthenticated'
import UserService from '../../utils/userService'
import Loading from '../../components/Loading'
import { createUuid, fetchLatestUuid } from './qrAPI'
import ValidQr from './ValidQr'
import InvalidQr from './InvalidQr'
import { uuidv4 } from '../../utils/helper'
import { useDispatch } from "react-redux";
import { resetHome } from "../home/homeSlice";
import { resetRequests } from "../request/requestSlice";
import { resetVisits } from "../visit/visitSlice";

const Qr = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [uuid, setUuid] = useState('')
  const [dateStr, setDateStr] = useState('')

  useEffect(() => {
    const loadQr = async () => {
      const res = await fetchLatestUuid()
      const uid = res.data.results[0]
      const now = new Date()
      if (uid?.expires > now.toISOString()) {
        setUuid(uid.uuid)
        setDateStr(uid.expires)
      }
      setLoading(false)
    }
    if (UserService.hasRole(['security'])) {
      loadQr()
      dispatch(resetHome())
      dispatch(resetRequests())
      dispatch(resetVisits())
    }
  }, [dispatch])

  const callExpired = () => {
    setUuid('')
    setDateStr('')
  }

  const generate = async () => {
    setLoading(true)
    const id = uuidv4()
    const now = new Date()
    now.setMinutes(now.getMinutes() + 10)
    const body = {
      uuid: id,
      expires: now.toISOString(),
    }
    await createUuid(body)
    setUuid(id)
    setDateStr(now.toISOString())
    setLoading(false)
  }

  return (
    <RenderOnAuthenticated>
      <RenderOnRole roles={['security']}>
        <Helmet>
          <title>Visitor Pass | QR</title>
        </Helmet>
        <div className="h-full flex flex-col items-center">
          <PageHeading title="QR - Walkin" />
          {loading ? (
            <Loading />
          ) : uuid ? (
            <ValidQr uuid={uuid} dateString={dateStr} callExpired={callExpired} />
          ) : (
            <InvalidQr handleClick={generate} />
          )}
        </div>
      </RenderOnRole>
    </RenderOnAuthenticated>
  )
}

export default Qr
