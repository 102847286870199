import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateVisitorState } from "../home/chatAPI";

const initialState = {
  status: 'idle'
}

export const startVisitorJourneyAsync = createAsyncThunk(
  'visitRequest/startVisitorJourney',
  async ({userId, state, message}) => {
    await updateVisitorState(userId, state)
    // eslint-disable-next-line no-undef
    chatSendMsg(message, userId)
  },
)

export const visitRequestSlice = createSlice({
  name: 'visitRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startVisitorJourneyAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(startVisitorJourneyAsync.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(startVisitorJourneyAsync.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const selectVisitRequestStatus = (state) => state.visitRequest.status

export default visitRequestSlice.reducer
