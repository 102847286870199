import React from 'react'
import QRCode from 'react-qr-code'

import { APP_URL } from '../../utils/constants'
import CountdownTimer from './CountdownTimer'

const ValidQr = ({ uuid, dateString, callExpired }) => {
  const date = new Date(dateString)
  const diff = Math.ceil((date.getTime() - Date.now()) / 1000)

  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <p className="font-semibold text-primary text-center">
        Scan the below code for your walkin
      </p>
      <div className='p-2'>
        <QRCode value={`${APP_URL}/walkin/${uuid}`} size={200} />
      </div>
      <CountdownTimer time={diff} callExpired={callExpired} />
    </div>
  )
}

export default ValidQr
