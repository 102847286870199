import axios from 'axios'
import UserService from './userService'
import { PARSE_URL, SOCKET_URL } from "./constants";

const _apiClient = axios.create({
  baseURL: PARSE_URL,
})

const _publicClient = axios.create({
  baseURL: SOCKET_URL,
})

const configure = () => {
  _apiClient.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`
        return Promise.resolve(config)
      }
      return UserService.updateToken(cb)
    }
    return config;
  })
}

const getApiClient = () => _apiClient
const getPublicClient = () => _publicClient

const HttpService = {
  configure,
  getApiClient,
  getPublicClient,
}

export default HttpService
