import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Layout from './components/Layout'
import Home from './features/home/Home'
import Request from './features/request/Request'
import Visit from './features/visit/Visit'
import Reports from './features/report/Reports'
import UnKnown from './components/UnKnown'
import Visits from './features/visit/Visits'
import Chat from './components/Chat'
import { useDispatch, useSelector } from 'react-redux'
import {
  createUserIdAsync,
  selectAppStatus,
  selectUserId,
} from './features/app/appSlice'
import VisitRequest from './features/visitRequest/VisitRequest'
import Walkin from './features/walkin/Walkin'
import Qr from './features/qr/Qr'
import Requests from './features/request/Requests'

const App = () => {
  const appStatus = useSelector(selectAppStatus)
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (appStatus === 'idle') {
      dispatch(createUserIdAsync())
    }
  }, [appStatus, dispatch])

  return (
    <Layout>
      <Helmet>
        <title>Visitor Pass</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/visits" element={<Visits />} />
        <Route path="/visits/:visitId" element={<Visit />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/requests/:requestId" element={<Request />} />
        <Route path="/visit-request/:requestId" element={<VisitRequest />} />
        <Route path="/walkin/:uuid" element={<Walkin />} />
        <Route path="/qr" element={<Qr />} />
        <Route path="*" element={<UnKnown />} />
      </Routes>
      {userId ? <Chat userId={userId} /> : null}
    </Layout>
  )
}

export default App
